import analytics from "@lib/analytics";

export const trackForm = (event, items, isTemplate) => {
  if (!items.length) return;
  const questionStats = items.reduce(
    (acc, it) => ({
      ...acc,
      [it.questionType]: !acc[it.questionType] ? 1 : acc[it.questionType] + 1,
    }),
    {}
  );
  analytics.track({
    event,
    properties: {
      ...questionStats,
      required: items.filter((q) => q.required).length,
      total: items.length,
      template: !!isTemplate,
    },
  });
};

export const trackFormSubmitted = (items, answers) => {
  if (!items?.length) return;
  const questionStats = items.reduce((acc, it, i) => {
    const answered = !!answers[i];
    if (!answered) return acc;
    return {
      ...acc,
      [it.questionType]: !acc[it.questionType] ? 1 : acc[it.questionType] + 1,
      total: items.length,
      answered: answers?.filter(Boolean).length,
      required: items.filter((q) => q.required).length,
    };
  }, {});
  analytics.track("form_submitted", { ...questionStats });
};
