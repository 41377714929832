import { useMemo } from "react";
import { useCollection } from "swr-firebase";

import { FormTemplateType } from "@lib/data/schemas/form-template";

import useAccessType from "./use-access-type";

export default function useFormTemplates(uid: string) {
  const { hasFullAccess } = useAccessType();
  const { data, error, add } = useCollection<FormTemplateType>(
    uid ? `users/${uid}/form_templates` : null,
    {
      orderBy: ["createdAt", "desc"],
      listen: true,
    }
  );

  const allTemplates = useMemo(
    () => data?.filter(({ status }) => status !== "deleted"),
    [data]
  );

  const templates = hasFullAccess
    ? allTemplates
    : allTemplates?.filter((t) => !t.internal);

  const internalTemplates = allTemplates?.filter((t) => t.internal);
  const activeInternalTemplates = internalTemplates?.filter(
    (t) => t.status === "active"
  );

  const externalTemplates = templates?.filter((t) => !t.internal);

  return {
    data,
    externalTemplates,
    templates,
    internalTemplates,
    activeInternalTemplates,
    error,
    add,
    loading: !templates && !error,
  };
}
