import { FC, useMemo, useState } from "react";
import { SanitizedAccountType } from "api-services/definitions/integrations";
import classNames from "classnames";
import { kebabCase, omit, orderBy } from "lodash";

import useFlow from "@hooks/use-flow";
import { displayNameFromContact } from "@lib/contacts";

import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import Label from "@components/Form/Label";
import FlagIcon from "@components/Icons/Flag3Icon";
import LocationIcon from "@components/Icons/LocationIcon";
import SortByAlphabeticalAzIcon from "@components/Icons/SortByAlphabeticalAzIcon";
import SortByAlphabeticalZaIcon from "@components/Icons/SortByAlphabeticalZaIcon";
import Modal from "@components/Modals/Modal";
import SearchBar from "@components/SearchBar";

type SortType = "asc" | "desc";

interface SidebarToolsProps {
  value: string;
  sort?: SortType;
  onClickSort: () => void;
  onChangeFilter: (value: string) => void;
}

const sortIcons = {
  asc: SortByAlphabeticalAzIcon,
  desc: SortByAlphabeticalZaIcon,
};

const SidebarTools: FC<SidebarToolsProps> = ({
  value,
  sort = "asc",
  onClickSort,
  onChangeFilter,
}) => {
  const SortIcon = sortIcons[sort];
  return (
    <div className="flex items-center space-x-2">
      <SearchBar
        placeholder="Who are you looking for"
        value={value}
        onChange={onChangeFilter}
      />
      <Button
        icon={<SortIcon className="w-5 h-5" />}
        onClick={onClickSort}
        square
      />
    </div>
  );
};

interface MemberListItemProps {
  member: SanitizedAccountType;
  onClick?: () => void;
  selected?: boolean;
}

const MemberListItem: FC<MemberListItemProps> = ({
  member,
  onClick,
  selected = false,
}) => (
  <div
    key={member.id}
    id={`member-list-item-${member.id}`}
    className={classNames(
      "flex items-center p-2 space-x-2 rounded-lg",
      selected ? "bg-action-950" : "hover:bg-grey-950 cursor-pointer"
    )}
    onClick={onClick}
  >
    <ClientAvatar client={member} size="xsmall" />
    <p className="flex-1 text-sm text-black-ink font-medium">
      {displayNameFromContact(member)}
    </p>
  </div>
);

interface SuggestedMemberProps {
  member: SanitizedAccountType;
}

// @TODO: remove the export once we implement the suggested member logic
//        and this component is used in this file again
//        - task: WEB-9305
export const SuggestedMember: FC<SuggestedMemberProps> = ({ member }) => (
  <div className="">
    <p className="flex items-center space-x-1 text-action-500 text-xs font-medium mb-2">
      <FlagIcon className="w-4 h-4" />
      <span>Suggested</span>
    </p>
    <MemberListItem member={member} selected />
  </div>
);

interface MembersListProps {
  members: SanitizedAccountType[];
  onClickMember: (memberId: string) => void;
  selected?: string;
}

const MembersList: FC<MembersListProps> = ({
  members,
  onClickMember,
  selected,
}) => {
  return (
    <div>
      <Label className="mb-2 font-medium text-xs">All members</Label>
      <div className="flex flex-col h-full">
        {members.map((member) => (
          <MemberListItem
            key={member.id}
            member={member}
            onClick={() => onClickMember(member.id)}
            selected={selected === member.id}
          />
        ))}
      </div>
    </div>
  );
};

interface MemberDetailsProps {
  member: SanitizedAccountType;
}

const MemberDetails: FC<MemberDetailsProps> = ({ member }) => (
  <div className="text-left grid gap-4">
    <ClientAvatar className="ml-0" client={member} size="largest" />
    <div>
      <h1 className="text-2xl font-medium mt-2">
        {displayNameFromContact(member)}
      </h1>
      {member.coachLocation && (
        <p className="text-grey-500 flex items-center">
          <LocationIcon className="h-6 w-6 mr-1" />
          {member.coachLocation}
        </p>
      )}
    </div>
    {member.bio && (
      <p className="text-grey-500 whitespace-pre-wrap">{member.bio}</p>
    )}
  </div>
);

interface MemberSelectorModalProps {
  shown: boolean;
  hide: () => void;
  members: SanitizedAccountType[];
}

const MemberSelectorModal: FC<MemberSelectorModalProps> = ({
  shown,
  hide,
  members,
}) => {
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState<"asc" | "desc">("asc");
  const { sharedData: scheduleFlow, setSharedData: setSchedulerFlow } =
    useFlow();
  const memberId = scheduleFlow?.memberId as string;
  const [selected, setSelected] = useState<string | null>(memberId);

  // @TODO: move this logic to an API endpoint when it is available
  const otherMembers = useMemo(
    () =>
      orderBy(
        members.filter((member) => {
          const searchKey = kebabCase(
            [displayNameFromContact(member), member.email].join(" ")
          );

          return !filter || searchKey.includes(filter);
        }),
        ["firstName", "lastName", "email"],
        [sort]
      ),
    [filter, sort]
  );

  const selectedMember = useMemo(() => {
    return members.find((m) => m.id === selected) || members[0];
  }, [selected, members]);

  const selectMember = (selectedMemberId: string) => {
    setSchedulerFlow({
      ...omit(scheduleFlow, "memberIds"),
      memberId: selectedMemberId,
    });
    hide();
  };

  const handleSort = () => setSort(sort === "asc" ? "desc" : "asc");

  const handleFilter = (value: string) => setFilter(kebabCase(value));

  return (
    <Modal className="h-full" show={shown} size="biggest" toggleShow={hide}>
      <div className="flex h-full flex-col sm:flex-row">
        <div className="flex-1 sm:max-w-[360px] border-b sm:border-r sm:border-b-0 border-grey-900 p-6 pb-0 overflow-y-auto space-y-6">
          <SidebarTools
            value={filter}
            sort={sort}
            onClickSort={handleSort}
            onChangeFilter={handleFilter}
          />
          <MembersList
            members={otherMembers}
            onClickMember={setSelected}
            selected={selected ?? ""}
          />
        </div>
        <div className="flex-1 p-6 space-y-4 overflow-y-auto">
          <div className="text-right">
            <Button
              className="w-full sm:w-auto"
              onClick={() => selectMember(selected ?? "")}
              primary
            >
              {`Continue with ${displayNameFromContact(selectedMember)}`}
            </Button>
          </div>
          <MemberDetails member={selectedMember} />
        </div>
      </div>
    </Modal>
  );
};

export default MemberSelectorModal;
