import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const LinkIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 2.5C2 2.22386 2.22386 2 2.5 2H7.5C7.77614 2 8 2.22386 8 2.5V3.5C8 3.77614 7.77614 4 7.5 4H5.41418L9.71749 8.3033C9.91275 8.49856 9.91275 8.81515 9.71749 9.01041L9.01038 9.71751C8.81512 9.91278 8.49853 9.91278 8.30327 9.71751L4 5.41424V7.5C4 7.77614 3.77614 8 3.5 8H2.5C2.22386 8 2 7.77614 2 7.5V2.5Z" />
    </svg>
  );
};

export default LinkIcon;
