import { useCallback, useState } from "react";
import { SanitizedAccountType } from "api-services/definitions/integrations";
import classNames from "classnames";
import { DateTime } from "luxon";

import useFlow from "@hooks/use-flow";
import { ProductType } from "@lib/data/schemas/product";
import { SchedulerType } from "@lib/data/schemas/scheduler";
import { displayItemAmountString } from "@lib/products";
import { TaxType } from "@lib/shared-types";

import { Button } from "@components/Button";
import { VARIANTS } from "@components/Client/SubmitButton";
import DetailsWrapper from "@components/DisplayDetails/DetailsWrapper";
import IconWithDetails from "@components/DisplayDetails/IconWithDetails";
import ProductDetails from "@components/DisplayDetails/ProductDetails";
import SafePaymentTooltip from "@components/DisplayDetails/SafePaymentTooltip";
import UnfoldingDetails from "@components/DisplayDetails/UnfoldingDetails";
import CalendarIcon from "@components/Icons/CalendarIcon";
import ClientIcon from "@components/Icons/ClientIcon";
import CreditCardIcon from "@components/Icons/CreditCardIcon";
import MeetWithDetail from "@components/PublicProfile/MeetWithDetail";

import MemberSelectorModal from "./MemberSelectorModal";

export interface BookingSchedulerDetailsProps {
  product?: ProductType;
  availability: SchedulerType & { account?: Partial<SanitizedAccountType> };
  taxType?: TaxType;
  altFirstIcon?: JSX.Element;
  hideProduct?: boolean;
  packageInstanceId?: string;
  owner?: SanitizedAccountType;
  members?: SanitizedAccountType[];
  canChangeMember?: boolean;
  memberId?: string;
  timeZone?: string;
}

const MemberSelector: React.FC<{
  members?: SanitizedAccountType[];
}> = ({ members }) => {
  const { sharedData: scheduleFlow } = useFlow();
  const memberId = scheduleFlow?.memberId as string;

  const [modalVisible, setModalVisible] = useState(false);

  const hide = useCallback(() => {
    setModalVisible(false);
  }, []);

  if (!members || !memberId) return null;

  return (
    <>
      <Button
        smaller
        className={classNames(VARIANTS.ghost, "text-sm px-4")}
        onClick={() => setModalVisible(true)}
      >
        Change
      </Button>
      {modalVisible && (
        <MemberSelectorModal shown hide={hide} members={members} />
      )}
    </>
  );
};

const BookingSchedulerDetails: React.FC<BookingSchedulerDetailsProps> = ({
  product,
  availability,
  taxType,
  altFirstIcon,
  hideProduct = false,
  owner,
  members,
  canChangeMember,
  timeZone,
}) => {
  const { sharedData: scheduleFlow } = useFlow();
  const memberId = scheduleFlow?.memberId as string;

  const account = members
    ? members.find((member) => member.id === memberId)
    : owner;

  return (
    <DetailsWrapper withoutdivide>
      <>
        <div className="divide-y divide-foreground/25">
          {altFirstIcon ? (
            altFirstIcon
          ) : (
            <IconWithDetails
              icon={<CalendarIcon />}
              title={`${availability.duration}-minute appointment`}
              subtitle={DateTime.local().setZone(timeZone).offsetNameLong}
            />
          )}
          {!!members?.length && memberId === "" && (
            <IconWithDetails
              title={`${members.length} members available total`}
              subtitle={"Choose after selected time slot"}
              icon={<ClientIcon />}
            />
          )}
          {account && (
            <div className="flex items-center">
              <MeetWithDetail account={account} />
              {canChangeMember && <MemberSelector members={members} />}
            </div>
          )}
          {product && !hideProduct && (
            <IconWithDetails
              icon={<CreditCardIcon />}
              title={`${displayItemAmountString(
                product.payment.total / 100,
                product.payment.currency
              )}`}
              subtitle="Taxes included."
              rightElement={<SafePaymentTooltip />}
            />
          )}
        </div>
        {(product || availability?.description) && (
          <UnfoldingDetails type="appointment">
            <div className="flex flex-col py-4 border-t border-foreground/25 w-full">
              {product && !hideProduct && (
                <div className="border-b border-foreground/25 mb-4 pb-2">
                  <ProductDetails
                    disableFold
                    hideIconDetails
                    product={product}
                    taxType={taxType}
                  />
                </div>
              )}
              <p className="text-foreground/70 text-sm whitespace-pre-wrap">
                {product?.description || availability?.description}
              </p>
            </div>
          </UnfoldingDetails>
        )}
      </>
    </DetailsWrapper>
  );
};

export default BookingSchedulerDetails;
