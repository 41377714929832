export const getMetaDescription = (
  fallBack: string,
  artifactDescription?: string,
  metaDescription?: string
) => {
  if (artifactDescription && artifactDescription !== "")
    return artifactDescription;
  if (metaDescription && metaDescription !== "") return metaDescription;
  return fallBack;
};
