import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const SortByAlphabeticalAzIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M9.406 6l4.25 11.375h-2.5l-.789-2.398H6.28l-.787 2.398H3L7.227 6h2.18zm-2.525 7.14h2.883L8.32 8.755 6.88 13.14z"
        clipRule="evenodd"
      ></path>
      <path d="M21.39 17.375v-1.758H17.2l4.066-5.398V8.922h-6.774v1.765h3.91l-4.035 5.352v1.336h7.024z"></path>
    </svg>
  );
};

export default SortByAlphabeticalAzIcon;
