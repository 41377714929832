import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";

import ClientAvatar from "@components/Client/ClientAvatar";
import IconWithDetails from "@components/DisplayDetails/IconWithDetails";

interface MeetWithDetailProps {
  account: Partial<AccountType>;
}

const MeetWithDetail: React.FC<MeetWithDetailProps> = ({ account }) => {
  return (
    <IconWithDetails
      className="gap-3"
      id="meet-with-container"
      title="Meet with"
      subtitle={`${displayNameFromContact(account)}`}
      icon={<ClientAvatar client={account} />}
      iconClassNames="bg-transparent !p-0"
      subtitleClassNames="font-medium"
    />
  );
};

export default MeetWithDetail;
