import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const SortByAlphabeticalZaIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.672 17.375v-1.828h-5.77l5.653-8.242V6H3.008v1.836h5.679L3 16.03v1.344h8.672z"></path>
      <path
        fillRule="evenodd"
        d="M17.867 8.273l3.617 9.102H19.22l-.653-1.781h-3.52l-.64 1.781h-2.273l3.594-9.102h2.14zm-2.244 5.72h2.356l-1.19-3.245-1.166 3.244z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default SortByAlphabeticalZaIcon;
