import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M8 2H6V4H3V20C3 21.1046 3.89543 22 5 22H7.5C7.77614 22 8 21.7761 8 21.5V20.5C8 20.2239 7.77614 20 7.5 20H5V6H19V8.5C19 8.77614 19.2239 9 19.5 9H20.5C20.7761 9 21 8.77614 21 8.5V4H18V2H16V4H8V2Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 15C20 16.2958 19.5892 17.4957 18.8907 18.4766L21.9497 21.5355C22.3402 21.9261 22.3402 22.5592 21.9497 22.9497C21.5592 23.3403 20.926 23.3403 20.5355 22.9497L17.4765 19.8908C16.4957 20.5892 15.2958 21 14 21C10.6863 21 8 18.3137 8 15C8 11.6863 10.6863 9 14 9C17.3137 9 20 11.6863 20 15ZM14 19C16.2091 19 18 17.2091 18 15C18 12.7909 16.2091 11 14 11C11.7909 11 10 12.7909 10 15C10 17.2091 11.7909 19 14 19Z"
      />
    </svg>
  );
};

export default Icon;
