import { FC } from "react";
import classNames from "classnames";
import moment from "moment";

import useFlow from "@hooks/use-flow";
import useTimezones from "@hooks/use-timezones";

import { Button } from "@components/Button";
import ArrowPaginationButtons from "@components/Buttons/ArrowPaginationButtons";
import SubmitButton, { VARIANTS } from "@components/Client/SubmitButton";
import Selectbox from "@components/Form/Selecbox";
import ClockIcon from "@components/Icons/ClockIcon";
import EventFoundIcon from "@components/Icons/EventFoundIcon";

const slotAvailability = ({ isMorning }) => (
  <div
    className={`mx-0.5 inline-block ${
      isMorning ? "bg-blue-700" : "bg-action-500"
    } p-1 rounded-full`}
  ></div>
);

interface BookingCalendarProps {
  slotDays: any;
  selectedDate: any;
  onSelectCalendarDate: any;
  date: any;
  setDate: any;
  roster: any;
  timeZone: string;
  onChangeTimeZone: (value: string) => void;
}

const BookingCalendar: FC<BookingCalendarProps> = ({
  slotDays,
  selectedDate,
  onSelectCalendarDate,
  date,
  setDate,
  roster,
  timeZone,
  onChangeTimeZone,
}) => {
  const { timeZones } = useTimezones();

  const currentDate = moment().add(date, "month");
  const currentMonth = currentDate.format("MMMM");
  const currentYear = currentDate.format("YYYY");
  const weekDays = moment.weekdaysShort();
  const daysInMonth = currentDate.daysInMonth();
  const firstOfMonth = currentDate.startOf("month").weekday();
  const findSlots = (date = selectedDate) =>
    slotDays.find(({ prettyDate }) => moment(prettyDate).isSame(date));
  const noAvailableSlots = (date) => !findSlots(date);
  const { sharedData: scheduleFlow, setSharedData: setSchedulerFlow } =
    useFlow();
  const memberId = scheduleFlow?.memberId;

  const checkNoSlotsThisMonth = () => {
    // If there are no slots at all, then no slots this month
    if (slotDays.length === 0) return true;

    const format = "MMMM YYYY"; // Eg: June 2021
    // Filter to keep only the slots of the current month
    const currentMonthSlotDays = slotDays.filter(({ prettyDate }) => {
      const slotMonthAndYear = moment(prettyDate).format(format);
      const currentMonthAndYear = currentDate.format(format);

      return slotMonthAndYear === currentMonthAndYear;
    });

    return currentMonthSlotDays.length === 0 ? true : false;
  };
  const noSlotsThisMonth = checkNoSlotsThisMonth();

  const nextMonth = () => {
    setDate(date + 1);
  };
  const prevMonth = () => {
    setDate(date - 1);
  };
  const isDateSelected = (day) => {
    if (!selectedDate) return;
    return currentDate.set("date", day).isSame(selectedDate);
  };

  return (
    <>
      <div className="flex space-x-4 mb-6">
        <div className="flex-1 font-medium text-lg text-foreground">
          {currentMonth} {currentYear}
        </div>
        <div className="flex items-center space-x-2">
          <Selectbox
            variant="white-label"
            className="w-full max-w-[240px]"
            value={timeZone}
            onChange={(e) => onChangeTimeZone(e.target.value)}
            options={timeZones.map((item) => ({
              label: item.label,
              value: item.value,
            }))}
          />
          <ArrowPaginationButtons
            variant="white-label"
            onClickPrevious={prevMonth}
            onClickNext={nextMonth}
          />
        </div>
      </div>

      <div className={classNames("mt-4", { "opacity-50": noSlotsThisMonth })}>
        {weekDays.map((day) => (
          <div
            key={day}
            style={{ width: "14.2%" }}
            className="text-center inline-block text-sm text-gray-400"
          >
            {day}
          </div>
        ))}
      </div>

      <div className="mt-4 relative">
        <div className={classNames({ "opacity-50": noSlotsThisMonth })}>
          {/* Render empty slots to start the month */}
          {[...Array(firstOfMonth).keys()].map((empty) => (
            <div
              key={empty}
              style={{ width: "14.2%" }}
              className="text-center inline-block p-4"
            />
          ))}

          {[...Array(daysInMonth).keys()].map((day) => {
            const correctDay = day + 1;
            const sameDayAsSelected = isDateSelected(correctDay);
            const givenDay = moment(currentDate.set("date", correctDay));
            const isBefore = givenDay.isBefore(moment(), "day");
            const morningSlots = findSlots(givenDay)?.slots?.filter(
              ({ pretty }) => pretty.toLowerCase().includes("am")
            );
            const eveningSlots = findSlots(givenDay)?.slots?.filter(
              ({ pretty }) => pretty.toLowerCase().includes("pm")
            );
            const dayClasses = classNames(
              "appointment-date relative text-md rounded-lg gap-1 text-center inline-block px-2 py-4 md:p-4 disabled:opacity-50",
              {
                "bg-foreground/7 hover:bg-foreground/20 text-foreground":
                  !isBefore && !sameDayAsSelected,
                "bg-transparent text-foreground/20":
                  isBefore && !sameDayAsSelected,
                "bg-accent focus:ring-0 focus:outline-none text-button-text":
                  sameDayAsSelected,
              }
            );

            return (
              <button
                key={day}
                onClick={() =>
                  onSelectCalendarDate(givenDay, findSlots(givenDay))
                }
                style={{ width: "12.4%", margin: "0.9%" }}
                className={dayClasses}
                data-heap-event-name="scheduler_client_chooses_date"
                disabled={isBefore || noAvailableSlots(givenDay)}
              >
                {correctDay}

                {morningSlots || eveningSlots ? (
                  <div className="absolute bottom-0.5 w-full left-0">
                    {morningSlots.length
                      ? slotAvailability({ isMorning: true })
                      : null}
                    {eveningSlots.length
                      ? slotAvailability({ isMorning: false })
                      : null}
                  </div>
                ) : null}
              </button>
            );
          })}
          {memberId && !roster && (
            <div className="bg-foreground/7  rounded-xl p-4 flex mt-4 items-center">
              <ClockIcon className="bg-accent text-button-text ring-accent ring-4 mr-4 rounded-full" />
              <div className="text-sm">
                <div className="text-foreground">
                  Can&apos;t find a good time
                </div>
                <div className="text-foreground/50">
                  Browse all members availabilitities
                </div>
              </div>
              <Button
                smaller
                className={classNames(VARIANTS.primary, "px-4 text-sm ml-auto")}
                onClick={async () => {
                  setSchedulerFlow({ ...scheduleFlow, memberId: "" });
                }}
              >
                Browse all
              </Button>
            </div>
          )}
        </div>
        {/* If there are no slots in the month, render banner with message + button to go to next month */}
        {noSlotsThisMonth ? (
          <div className="absolute text-foreground top-0 left-1/2 transform mt-24 -translate-x-1/2 w-3/4 bg-background  rounded-lg  text-center">
            <div className="w-full h-full flex flex-col items-center bg-background/90 px-4 py-8">
              <EventFoundIcon />
              <p className="text-foreground font-medium text-lg mt-2.5 ">
                No availability this month
              </p>
              <SubmitButton
                onClick={nextMonth}
                actionTitle="Jump to next month"
                size="regular"
                className="mt-4 mx-4  w-full"
              />
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BookingCalendar;
