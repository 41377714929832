import { FC, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";

import { displayNameFromContact } from "@lib/contacts";

import SubmitButton from "@components/Client/SubmitButton";
import TextField from "@components/Client/TextField";
import TextAreaForm from "@components/Form/TextAreaForm";
import CheckIcon from "@components/Icons/CheckIcon";

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
}

interface AppointmentContactFormProps {
  recipientAccountId: string;
  recipientName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

const AppointmentContactForm: FC<AppointmentContactFormProps> = ({
  recipientAccountId,
  recipientName,
  firstName,
  lastName,
  email,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    mode: "onTouched",
    defaultValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      message: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit: SubmitHandler<FormValues> = async (data, e) => {
    e?.preventDefault();
    setLoading(true);

    if (!data) return;

    try {
      const endpoint = `/api/v1/accounts/${recipientAccountId}/message`;
      const response = await axios.post(endpoint, {
        visitorName: displayNameFromContact(data),
        email: data?.email,
        message: data?.message,
      });

      if (response) setMessageSent(true);
    } catch (e) {
      setLoading(false);
      setErrorMessage(e.message);
    }
  };

  if (messageSent) {
    return (
      <div className="w-full flex justify-center">
        <div className="flex items-center mx-auto">
          <CheckIcon width={30} height={30} className="text-green-600 mr-4" />
          <p className="text-foreground text-xl font-medium">
            Your message was sent{recipientName ? ` to ${recipientName}` : ""}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full max-w-[480px]">
      <p className="font-medium text-lg text-foreground">
        Please leave a message and I will get back to you as soon as possible
      </p>

      <TextField
        id="firstName"
        type="text"
        placeholder="First name"
        required={true}
        register={register}
        errors={errors}
        errorMessage="First name is required"
      />

      <TextField
        id="lastName"
        type="text"
        placeholder="Last name"
        register={register}
        errors={errors}
      />

      <TextField
        id="email"
        type="text"
        placeholder="Email"
        required={true}
        register={register}
        errors={errors}
        errorMessage="Email is required"
      />

      <p className="font-medium text-lg mt-8 text-foreground">Message</p>
      <TextAreaForm
        textClassName="bg-background text-foreground hover:bg-background focus:bg-background border-foreground/20 placeholder-foreground/50 focus:border-accent"
        placeholder="When would be a good time for us to meet?"
        name="message"
        register={register}
        errors={errors}
        errorMessage="Message is required"
        required
      />

      {errorMessage ? (
        <div className="mt-8 p-3 text-sm rounded-md text-peach-600 bg-peach-950 flex flex-row items-center">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.8716 5.54947C12.4893 4.86982 11.5107 4.86982 11.1284 5.54947L3.83825 18.5097C3.46329 19.1763 3.945 20 4.70983 20H19.2901C20.055 20 20.5367 19.1763 20.1617 18.5097L12.8716 5.54947ZM11 10.5C11 10.2239 11.2238 10 11.5 10H12.5C12.7761 10 13 10.2239 13 10.5V14.5C13 14.7761 12.7761 15 12.5 15H11.5C11.2238 15 11 14.7761 11 14.5V10.5ZM11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17Z"
            />
          </svg>
          <p className="ml-2">{errorMessage}</p>
        </div>
      ) : null}

      <div className="mt-12 text-center flex justify-center">
        <SubmitButton
          actionTitle="Send"
          onAction={handleSubmit(onSubmit)}
          errors={errors}
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default AppointmentContactForm;
