import { useCallback, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import { useHighlightedTextContext } from "@contexts/highlighted";

import CloseIcon from "@components/Icons/CloseIcon";
import SearchIcon from "@components/Icons/SearchIcon";

type Props = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
};

export const ListSearchBar: React.FC<Omit<Props, "value" | "onChange">> = (
  props
) => {
  const { setHighlight: onChange, highlight } = useHighlightedTextContext();
  return <SearchBar onChange={onChange} value={highlight} {...props} />;
};

const SearchBar: React.FC<Props> = ({
  value,
  onChange,
  placeholder = "Search..",
  className,
}) => {
  const inputEl = useRef(null);
  const [isFocused, setFocused] = useState(false);

  const onFocus = useCallback(() => setFocused(true), []);
  const onBlur = useCallback(() => setFocused(false), []);

  useEffect(() => {
    const handleFocus = () => {
      const isFocused = document.activeElement == inputEl.current;

      // If the feedback modal or the private todos side modal is showing,
      // disable focus on keydown so that the searchbar doesn't focus
      // and the user can type in the feedback modal
      const modal = document.querySelector("#modal");
      const sideModal = document.querySelector("#private-todos-side-modal");
      const backdrop = document.querySelector("#backdrop");

      if (
        isFocused ||
        modal?.textContent ||
        sideModal?.textContent ||
        backdrop ||
        inputEl.current === null
      )
        return;

      inputEl.current.focus();
    };

    window.addEventListener("keydown", handleFocus);

    return () => window.removeEventListener("keydown", handleFocus);
  }, []);

  return (
    <div
      className={classNames(
        "relative self-center flex-1 max-w-screen-lg",
        className
      )}
    >
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <span>
          <SearchIcon
            // Focused but nothing typed: yellow, something typed: black, otherwise: grey
            className={
              isFocused && value.length === 0
                ? "text-action-600"
                : value.length > 0
                ? "text-black-ink"
                : "text-grey-500"
            }
          />
        </span>
      </div>
      <input
        name="search"
        className="w-full rounded-lg px-12 py-2.5 bg-grey-950 placeholder-grey-500 focus:placeholder-action-600 border-none focus:outline-none focus:ring-0"
        placeholder={isFocused ? "Start typing..." : placeholder}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={inputEl}
      />
      {value.length > 0 ? (
        <div className="absolute inset-y-0 right-0 pr-2.5 flex items-center">
          <span
            className="cursor-pointer transition duration-150 hover:bg-grey-950 p-3 rounded-lg"
            onClick={() => onChange("")}
          >
            <CloseIcon className="w-5 h-5" />
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default SearchBar;
