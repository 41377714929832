import { useEffect, useState } from "react";
import axios from "axios";

import { CouponType } from "@lib/data/schemas/coupon";
import {
  displayItemAmountString,
  displayTaxTypeString,
  getFrequencyLabel,
} from "@lib/products";
import { TaxType } from "@lib/shared-types";

import PriceDetails from "./PriceDetails";

type InvoiceTotalsType = {
  tax: number;
  total: number;
  discount: number;
  amount: number;
};

interface ProductDetailsProps {
  // todo
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  product: any;
  taxType?: TaxType;
  coupon?: CouponType;
  hideIconDetails?: boolean;
  disableFold?: boolean;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({
  product,
  taxType,
  coupon,
  hideIconDetails,
  disableFold,
}) => {
  const { payment } = product;
  const { currency, amount, total, taxTypeId, payeeId, recurring } = payment;
  const [invoiceTotals, setInvoiceTotals] = useState<InvoiceTotalsType | null>(
    null
  );
  const isInclusive = taxType?.inclusive;

  useEffect(() => {
    const calculateTotal = async () => {
      const totalsResponse = await axios.post("/api/v1/calculate-invoice", {
        taxTypeId,
        invoiceValue: isInclusive ? total : amount,
        payeeId,
        ...(coupon && { couponId: coupon.id }),
      });
      setInvoiceTotals(totalsResponse.data);
    };
    calculateTotal();
  }, [product, coupon, taxTypeId, amount, payeeId]);

  const getPrettyPrice = (amount: number) =>
    displayItemAmountString(amount < 0 ? 0 : amount / 100, currency) as string;

  const couponIsPercent = coupon?.unit === "%";
  const multiplier = couponIsPercent ? 1 : 100;
  const invDiscount = invoiceTotals?.discount as number;
  const totalWithCoupon = total - invDiscount * multiplier;
  const couponIsOnce = coupon?.duration?.type === "once";

  const totalWithoutCoupon = getPrettyPrice(total);
  const totalHeader = getPrettyPrice(coupon ? totalWithCoupon : total);
  const taxTotal = getPrettyPrice(invoiceTotals?.tax || total - amount);
  const taxHeader = displayTaxTypeString(taxType);
  const subtotal = getPrettyPrice(amount);
  const subtotalWithTax = getPrettyPrice(amount + invoiceTotals?.tax);
  const discount = getPrettyPrice(invDiscount * multiplier);

  const paymentRecurring = recurring
    ? `/${getFrequencyLabel(recurring.interval, recurring.intervalCount)}`
    : "";
  const headerWithRecurring =
    couponIsOnce && recurring
      ? totalWithoutCoupon + paymentRecurring
      : totalHeader + paymentRecurring;

  const items = [
    {
      header: `1x ${product.title}`,
      price: subtotal,
    },
  ];

  if (!invoiceTotals) return null;

  return (
    <PriceDetails
      taxTotal={taxTotal}
      taxHeader={taxHeader}
      subtotalWithTax={subtotalWithTax}
      subtotal={subtotal}
      totalHeader={totalHeader}
      items={items}
      coupon={coupon}
      discount={discount}
      headerWithRecurring={headerWithRecurring}
      oneTimeDiscount={couponIsOnce && recurring}
      paymentCount={payment?.recurring?.paymentCount}
      hideIconDetails={hideIconDetails}
      disableFold={disableFold}
    />
  );
};

export default ProductDetails;
