import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="18"
        cy="18"
        r="2"
        stroke="currentColor"
        strokeWidth="2"
        fill="white"
      />
      <path d="M12 2H15C17.2091 2 19 3.79086 19 6C19 8.20914 17.2091 10 15 10H7.5C5.567 10 4 11.567 4 13.5C4 15.433 5.567 17 7.5 17H12V19H7C4.23858 19 2 16.7614 2 14V13C2 10.2386 4.23858 8 7 8H15C16.1046 8 17 7.10457 17 6C17 4.89543 16.1046 4 15 4H12V2Z" />
      <path d="M12.9497 17.5355L9.76777 14.3536C9.5725 14.1583 9.25592 14.1583 9.06066 14.3536L8.35355 15.0607C8.15829 15.2559 8.15829 15.5725 8.35355 15.7678L10.2071 17.6213C10.4024 17.8166 10.4024 18.1332 10.2071 18.3284L8.35358 20.182C8.15832 20.3772 8.15832 20.6938 8.35358 20.8891L9.06069 21.5962C9.25595 21.7914 9.57253 21.7914 9.7678 21.5962L12.9498 18.4142L13.0233 18.3459C13.2279 18.1558 13.237 17.8349 13.0437 17.6334L12.9497 17.5355Z" />
    </svg>
  );
};

export default Icon;
